import React, { useContext, useEffect, useRef } from 'react';
import * as THREE from 'three';
import { RoomSelectionContext } from "../../app/RoomSelectionProvider";

import CameraControlsDefault from 'camera-controls';
import { ReactThreeFiber, extend, useThree, useFrame } from "@react-three/fiber";
import { Vector3 } from "three";

// @ts-ignore
CameraControlsDefault.install({ THREE: THREE });
extend({ CameraControlsDefault });

declare global {
  namespace JSX {
    interface IntrinsicElements {
      cameraControlsDefault: ReactThreeFiber.Node<CameraControlsDefault, typeof CameraControlsDefault>;
    }
  }
}

const ZOOMED_OUT_DEFAULT = 4;
const ZOOMED_IN_DEFAULT = 8;

const MobileOrbitControls = () => {
  const { camera, gl } = useThree();

  const cameraControls = useRef<CameraControlsDefault | null>(null);

  // Set initial position
  useEffect(() => {
    let cameraControlsInstance = cameraControls.current;

    cameraControlsInstance?.setPosition(600, 570, 0);
    cameraControlsInstance?.zoom(ZOOMED_OUT_DEFAULT);
    return () => {
      // HMR Fix
      cameraControlsInstance?.zoom(ZOOMED_IN_DEFAULT * -1);
      cameraControlsInstance?.dispose();
    };
  }, []);

  const { selectedRoomMesh } = useContext(RoomSelectionContext);

  useEffect(() => {
    if(selectedRoomMesh) {
      const worldTargetPosition = new Vector3();
      selectedRoomMesh.localToWorld(worldTargetPosition);

      cameraControls.current?.setTarget(
        worldTargetPosition.x,
        0,
        worldTargetPosition.z,
        true,
      );

      // @ts-ignore
      if(cameraControls.current?._zoom <= ZOOMED_IN_DEFAULT) {
        cameraControls.current?.zoomTo(ZOOMED_IN_DEFAULT, true);
      }
    } else {
      cameraControls.current?.setTarget(0, 0, 0, true);
      cameraControls.current?.zoomTo(ZOOMED_OUT_DEFAULT, true);
    }
  }, [selectedRoomMesh]);

  useFrame((_, delta) => cameraControls.current?.update(delta));

  return (
    <cameraControlsDefault
      ref={cameraControls}
      // @ts-ignore
      args={[camera, gl.domElement]}
      minPolarAngle={Math.PI / 3}
      maxPolarAngle={Math.PI / 3}
      maxZoom={13}
      minZoom={4}
      minDistance={1000}
      // @ts-ignore
      touches={{
        // @ts-ignore
        one: 32,
        // @ts-ignore
        two: 512,
      }}
    />
  );
}

export default MobileOrbitControls;
