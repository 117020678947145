import React, { FC, useContext } from "react";
import Pill from "../theme/Pill";
import Trans from "../i18n";
import { Floors, RoomsByFloor } from "../navigation/types";
import RoomName from "./RoomName";
import styled from "styled-components";
import { RoomSelectionContext } from "../app/RoomSelectionProvider";
import { FilterType } from "./types";

const Wrapper = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const FloorListItem = styled.li``;

const FloorNameWrapper = styled.div`
  background: white;
  position: sticky;
  top: 0;
  padding: 8px 0 0 0;
`;

const RoomsListWrapper = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

interface Props {
  items: RoomsByFloor;
  onRoomSelected: (roomId: string) => void;
}
const RoomsByFloorListing: FC<Props> = ({
  items,
  onRoomSelected,
}) => {
  const {
    addFilter,
    selectedRoomId,
  } = useContext(RoomSelectionContext);

  return (
    <Wrapper>
      {Object.keys(items).map((floorName) => {
        return (
          <React.Fragment key={floorName}>
            <FloorListItem>
              <FloorNameWrapper>
                <Pill
                  onClick={() => addFilter!(floorName as FilterType)}
                >
                  <Trans transKey={floorName} />
                </Pill>
              </FloorNameWrapper>
              <RoomsListWrapper>
                {items[floorName as Floors].map(room => (
                  <RoomName
                    key={room._id}
                    onClick={() => onRoomSelected(room._id)}
                    isActive={selectedRoomId === room._id}
                  >
                    <Trans transKey={`${room._name}`} />
                  </RoomName>
                ))}
              </RoomsListWrapper>
            </FloorListItem>
          </React.Fragment>
        )
      })}
    </Wrapper>
  )
}

export default RoomsByFloorListing;
