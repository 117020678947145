import React, { FC, Suspense } from 'react';
import MapExplorer from '../map-explorer';
import Canvas from './Canvas';
import styled, { ThemeProvider } from "styled-components";
import CameraControls from "../controls/camera";
import RoomSelectionProvider from "./RoomSelectionProvider";
import Lights from "./Lights";
import Effects from "./Effects";
import Navigation from "../navigation";
import TransProvider from "../i18n/TransProvider";
import LanguageSwitcher from "../i18n/LanguageSwitcher";
import light from "../theme/light";
import MobileNavigationProvider from "./MobileNavigationProvider";

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const HeaderWrapper = styled.div`
  z-index: 9;
  position: absolute;
  top: 0;
  left: 0;
  padding: 8px;

  display: flex;
  gap: 10px;
`;

const Logo = styled.img`
  max-width: 100px;
  background: white;
  box-shadow: ${({theme}) => theme.boxShadow};
  padding: 8px;
`;

const App: FC = () => {
  return (
    <ThemeProvider theme={light}>
      <TransProvider>
        <Wrapper>
          <MobileNavigationProvider>
            <RoomSelectionProvider>
              <HeaderWrapper>
                <LanguageSwitcher/>
                <Logo src={"/assets/images/logo.png"}/>
              </HeaderWrapper>
              <Navigation />
              <Canvas>
                <Suspense fallback={null}>
                  <Lights/>
                  <group>
                    <MapExplorer/>
                  </group>
                  <CameraControls/>
                  <Effects/>
                </Suspense>
              </Canvas>
            </RoomSelectionProvider>
          </MobileNavigationProvider>
        </Wrapper>
      </TransProvider>
    </ThemeProvider>
  );
}

export default App;
