import { createContext, FC, PropsWithChildren, useState } from "react";

interface ContextFields {
  searchIsCollapsed: boolean;
  toggleSearch?: () => void;
}

export const MobileNavigationContext = createContext<ContextFields>({
  searchIsCollapsed: true,
  toggleSearch: undefined,
});

const MobileNavigationProvider: FC<PropsWithChildren> = ({
  children
}) => {
  const [searchIsCollapsed, setSearchIsCollapsed] = useState(true);

  const toggleSearch = () => {
    setSearchIsCollapsed(!searchIsCollapsed);
  }

  return (
    <MobileNavigationContext.Provider value={{
      searchIsCollapsed,
      toggleSearch,
    }}>
      {children}
    </MobileNavigationContext.Provider>
  )
}

export default MobileNavigationProvider;
