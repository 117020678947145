import { FC } from "react";
import useTranslation from "./useTranslation";

interface Props {
  transKey: string;
}

const Trans: FC<Props> = ({
  transKey
}) => {
  const t = useTranslation();

  return (
    <span>
      {t(transKey)}
    </span>
  )
}

export default Trans;
