import { Canvas } from "@react-three/fiber";
import { FC, PropsWithChildren, useContext } from "react";
import { RoomSelectionContext } from "./RoomSelectionProvider";

const OwnCanvas: FC<PropsWithChildren> = ({
  children,
}) => {
  const {
    resetSelection,
  } = useContext(RoomSelectionContext);

  // TODO: This is too tightly coupled. Need a way to expose the
  //  onPointerMissed event and hook into it from elsewhere
  const handleClickOutside = () => {
    resetSelection!();
  }
  return (
    <Canvas
      orthographic
      onPointerMissed={handleClickOutside}
      dpr={window.devicePixelRatio}
      gl={{
        antialias: true,
        logarithmicDepthBuffer: true
      }}
      className={"main-canvas"}
      // linear={true}
    >
      {children}
    </Canvas>
  );
}

export default OwnCanvas;
