import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { RoomSelectionContext } from "../app/RoomSelectionProvider";

const Wrapper = styled.div<{ isVisible: boolean }>`
  flex-direction: column;
  align-items: center;
  opacity: ${({ isVisible }) => isVisible ? 1 : 0};
  transition: 0.1s ease opacity;
  margin: 8px 0;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md }) {
    display: flex;
  }
`;

const Button = styled.button`
  border: none;
  background-color: white;
  width: 40px;
  height: 40px;
  display: block;
  margin: 0 0 8px 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60%;
  box-shadow: ${({ theme }) => theme.boxShadow};
  cursor: pointer;
`;

const UpButton = styled(Button)`
  background-image: url("/assets/images/arrow-up.png");
`;

const ResetButton = styled(Button)`
  background-image: url("/assets/images/hamburger.png");
`;

const DownButton = styled(Button)`
  background-image: url("/assets/images/arrow-down.png");
`;

const FloorNavigationButtons: FC = () => {
  const {
    selectedFloorIndex,
    goOneFloorDown,
    goOneFloorUp,
    resetSelection,
  } = useContext(RoomSelectionContext);


  return (
    <Wrapper isVisible={selectedFloorIndex !== null}>
      <UpButton
        onClick={goOneFloorUp}
      />
      <ResetButton
        onClick={resetSelection}
      />
      <DownButton
        onClick={goOneFloorDown}
      />
    </Wrapper>
  )
}

export default FloorNavigationButtons;
