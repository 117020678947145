import React, { FC, useContext } from 'react';
import { TransContext } from "./TransProvider";
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: white;
  box-shadow: ${({ theme }) => theme.boxShadow};
  padding: 8px;
`;

const Button = styled.button<{ isActive: boolean }>`
  border: none;
  padding: 0.5em;
  background-color: ${({ theme, isActive }) => isActive ? theme.primary : 'unset'};
  box-shadow: ${({ isActive, theme }) => isActive ? theme.boxShadowInsetActive : theme.boxShadowInsetInactive};
  color: black;
  font-weight: bold;
  font-size: 1.1em;
  transition: box-shadow 0.1s ease, background-color 0.1s ease;
  cursor: pointer;
`;

const RomanianLanguageButton = styled(Button)``;
const EnglishLanguageButton = styled(Button)``;

const LanguageSwitcher: FC = () => {
  const {
    activeLanguage,
    setActiveLanguage
  } = useContext(TransContext);

  return (
    <Wrapper>
      <RomanianLanguageButton
        onClick={() => setActiveLanguage!('romanian')}
        isActive={activeLanguage === 'romanian'}
      >
        RO
      </RomanianLanguageButton>
      <EnglishLanguageButton
        onClick={() => setActiveLanguage!('english')}
        isActive={activeLanguage === 'english'}
      >
        EN
      </EnglishLanguageButton>
    </Wrapper>
  )
}

export default LanguageSwitcher;
