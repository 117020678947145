import React, { useState, FC, PropsWithChildren, createContext } from "react";

const DEFAULT_LANGUAGE = 'romanian';

interface ContextValues {
  activeLanguage: string;
  setActiveLanguage?: (language: string) => void;
}

export const TransContext = createContext<ContextValues>({
  activeLanguage: DEFAULT_LANGUAGE,
  setActiveLanguage: undefined,
})

const TransProvider: FC<PropsWithChildren> = ({ children }) => {
  const [activeLanguage, setActiveLanguage] = useState(DEFAULT_LANGUAGE);

  const onSetActiveLanguage = (language: string) => {
    if(!language) {
      return null;
    }
    setActiveLanguage(language);
  }

  return (
    <TransContext.Provider value={{
      activeLanguage,
      setActiveLanguage: onSetActiveLanguage,
    }}>
      { children }
    </TransContext.Provider>
  )
}

export default TransProvider;
