import { FilterType } from "./types";
import { Floors, Room, RoomsByFloor } from "../navigation/types";
import rooms from "../navigation/rooms.json";
import { groupRoomsByFloor } from "./utils";
import { floors } from "../app/constants";

interface Args {
  activeFilters: FilterType[];
}

interface Fields {
  items: Room[] | RoomsByFloor;
}

type Hook = (args: Args) => Fields;

const filterRooms = (rooms: Room[], activeFilters: FilterType[]) => {
  // We need to change the filtering logic if activeFilters contains a floor filter
  const filteredByFloor = activeFilters.some((filter) => floors.includes(filter as Floors));

  // We only need to do this if there are more filters active (one of which is a floor filter)
  if(filteredByFloor && activeFilters.length > 1) {
    return rooms.filter((room) => {
      const {_floor, _tags} = room;

      return (
        activeFilters.includes(_floor) &&
        activeFilters.filter((filter) => filter !== _floor).every((filter) => _tags.includes(filter))
      );
    });
  }

  // If there are no floor filters, we can just filter by tags
  return rooms.filter((room) => {
    const {_floor, _tags} = room;

    return (
      activeFilters.includes(_floor) ||
      activeFilters.filter((filter) => filter !== _floor).every((filter) => _tags.includes(filter))
    );
  });
};

const useRoomListing: Hook = ({
  activeFilters,
}) => {
  const items = activeFilters.length > 0 ? filterRooms(rooms._roomList, activeFilters) : groupRoomsByFloor(rooms._roomList);

  return {
    items,
  }
}

export default useRoomListing;
