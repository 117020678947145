import { FC, useContext } from "react";
import { Room } from "../navigation/types";
import styled from 'styled-components';
import RoomName from "./RoomName";
import Trans from "../i18n";
import { RoomSelectionContext } from "../app/RoomSelectionProvider";
import { Result,  FilterType, RoomTag } from "./types";
import Pill from "../theme/Pill";

const Wrapper = styled.ul`
  list-style-type: none;
  margin: 8px 0 0 0;
  padding: 0;
`;

const DEBUG_LISTING = false;

interface Props {
  items: Result[];
  onRoomSelected: (roomId: string) => void;
  onTagSelected: (tag: RoomTag) => void;
}

const isRoom = (item: Result): item is Room => typeof item !== "string";
const isTag = (item: Result): item is RoomTag => typeof item === "string";

const Results: FC<Props> = ({
  items,
  onRoomSelected,
  onTagSelected,
}) => {
  const {
    selectedRoomId,
  } = useContext(RoomSelectionContext);

  return (
    <Wrapper>
      {items.length === 0 && <Trans transKey="no_results_message" /> }

      {items.map((item) => {
        if(isRoom(item)) {
          return (
            <RoomName
              key={item._id}
              isActive={selectedRoomId === item._id}
              onClick={() => onRoomSelected(item._id)}
            >
              <Trans transKey={item._name}/>

              {DEBUG_LISTING && (
                <>
                  <br/>
                  <small>({item._floor})</small>
                  <br/>
                  <small>{item._tags.map((tag) => <Trans transKey={tag} key={tag}/>)}</small>
                </>
              )}
            </RoomName>
          );
        }

        if(isTag(item)) {
          return (
            <Pill onClick={() => onTagSelected(item as FilterType)} key={item}>
              <Trans transKey={item}/>
            </Pill>
          )
        }

        return null;
      })}
    </Wrapper>
  );
}

export default Results;
