export enum Floors {
  UNDERGROUND = "SUBSOL",
  FIRST_FLOOR = "PARTER",
  SECOND_FLOOR = "ETAJ 1",
  THIRD_FLOOR = "ETAJ 2",
  FOURTH_FLOOR = "ETAJ 3",
  FIFTH_FLOOR = "ETAJ 4",
  ATTIC = "POD",
}

export interface Room {
  _id: string;
  _name: string;
  _description: string;
  _floor: string;
  _tags: string[];
  _hiddenKeywords: string;
}

export type RoomsByFloor = Record<Floors, Room[]>;
