import { useThree } from "@react-three/fiber";
import { TOUCH, Vector3 } from "three";
import { OrbitControls } from "@react-three/drei";
import React, { FC, useEffect, useRef } from "react";

const target = new Vector3(0,0,0);

const DesktopOrbitControls: FC = () => {
  const ref = useRef();

  const { camera } = useThree();

  // Set initial position
  useEffect(() => {
    camera.position.x = 600;
    camera.position.y = 570;
    camera.position.z = 0;
    camera.zoom = 7;
    camera.updateProjectionMatrix();
    // eslint-disable-next-line
  }, []);

  return (
    <OrbitControls
      makeDefault
      camera={camera}
      target={target}
      // @ts-ignore
      ref={ref}
      minPolarAngle={Math.PI / 3}
      maxPolarAngle={Math.PI / 3}
      rotateSpeed={0.7}
      zoomSpeed={1}
      enablePan={false}
      enableZoom={false}
      minDistance={100}
      touches={{
        ONE: TOUCH.ROTATE,
        TWO: TOUCH.DOLLY_PAN,
      }}
    />
  )
}

export default DesktopOrbitControls;
