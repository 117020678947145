import { DefaultTheme } from 'styled-components';

const light: DefaultTheme = {
  primary: '#FCBA4B',
  gray: '#D8D8D8',
  boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
  boxShadowInsetActive: 'inset 2px 2px 4px rgba(0, 0, 0, 0.1)',
  boxShadowInsetInactive: 'inset 0px 0px 0px rgba(0, 0, 0, 0)',
  breakpoints: {
    md: '1181px',
  }
}

export default light;
