import { Object3D } from "three";

const TARGET_IS_ROOM = /^[EPS]\d+(?:_\d+)?$/;

const BASEMENT_BLACKLISTED_ROOMS = ['S71', 'S70', 'S72', 'S59', 'S73', 'S41', 'S69'];
const FIRST_FLOOR_BLACKLISTED_ROOMS = ['P80', 'P81', 'P79', 'P78', 'P77', 'P76', 'P72', 'P74', 'P75', 'P68', 'P71'];
const SECOND_FLOOR_BLACKLISTED_ROOMS = ['E1_52', 'E1_51', 'E1_58', 'E1_53', 'E1_57', 'E1_54', 'E1_56', 'E1_55', 'E1_42'];
const THIRD_FLOOR_BLACKLISTED_ROOMS = ['E2_48', 'E2_55', 'E2_54', 'E2_49', 'E2_53', 'E2_50', 'E2_52', 'E2_51', 'E2_42'];
const FOURTH_FLOOR_BLACKLISTED_ROOMS = ['E3_50', 'E3_51', 'E3_53', 'E3_52', 'E3_55', 'E3_54', 'E3_57', 'E3_56', 'E3_37'];
const FIFTH_FLOOR_BLACKLISTED_ROOMS = ['E4_43', 'E4_44', 'E4_45', 'E4_46', 'E4_47', 'E4_48', 'E4_49', 'E4_51', 'E4_50', 'E4_36'];
const SIXTH_FLOOR_BLACKLISTED_ROOMS = ['E52_3', 'E52_4', 'E52_5', 'E51_2', 'E51_3'];

const BLACKLISTED_ROOMS = [
  ...BASEMENT_BLACKLISTED_ROOMS,
  ...FIRST_FLOOR_BLACKLISTED_ROOMS,
  ...SECOND_FLOOR_BLACKLISTED_ROOMS,
  ...THIRD_FLOOR_BLACKLISTED_ROOMS,
  ...FOURTH_FLOOR_BLACKLISTED_ROOMS,
  ...FIFTH_FLOOR_BLACKLISTED_ROOMS,
  ...SIXTH_FLOOR_BLACKLISTED_ROOMS,
];

const targetIsRoom = (object: Object3D) => {
  return object.name.match(TARGET_IS_ROOM) && BLACKLISTED_ROOMS.indexOf(object.name) === -1;
}
export default targetIsRoom;
