import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import FloorNavigationButtons from "./FloorNavigationButtons";
import RoomsList from "../rooms-list";
import { MobileNavigationContext } from "../app/MobileNavigationProvider";
import { RoomSelectionContext } from '../app/RoomSelectionProvider';

import RoomInfo from "../room-info";
import Trans from "../i18n";

const Wrapper = styled.div<{ collapsed: boolean }>`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9;

  width: 100%;
  height: 100%;
  min-height: 100%;
  max-height: 100vh;
  overflow-x: hidden;
  
  backdrop-filter: ${({collapsed}) => collapsed ? 'none' : 'blur(10px)'};

  // On mobile, allow pointer events to pass through when
  // room listing is closed since we want the interaction
  // to reach the canvas underneath
  pointer-events: ${({collapsed}) => collapsed ? 'none' : 'all'};

  touch-action: none;
  
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md }) {
    display: grid;
    grid-template-columns: 65% 40px auto;
    
    width: 100%;
    min-width: 400px;
    backdrop-filter: unset;
    // Always allow pointer events to pass through on desktop 
    pointer-events: none;
  }
`;

const FloorNavigationWrapper = styled.div`
  display: none;
  pointer-events: all;
  
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md }) {
    display: block;
  }
`;

const RoomInfoWrapper = styled.div`
  width: 100%;
  
  position: absolute;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: flex-end;
  
  overflow: hidden;
  
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md }) {
    position: static;
  }
  
  // pointer events are enabled in the child's wrapper component
  pointer-events: none;
`;

const RoomsListWrapper = styled.div<{ collapsed: boolean }>`
  width: 100%;
  height: 100%;
  
  // On mobile, allow pointer events to pass through when
  // room listing is closed since we want the interaction
  // to reach the canvas underneath
  pointer-events: ${({collapsed}) => collapsed ? 'none' : 'all'};

  transition: transform 0.1s ease;
  transform: translateX(${({ collapsed }) => collapsed ? '100%' : '0%'});


  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md }) {
    // Always allow pointer events to pass through on desktop 
    pointer-events: all;

    transition: unset;
    transform: unset;
  }
`;

const MobileNavigationTrigger = styled.button<{ isCollapsed: boolean }>`
  z-index: 10;
  position: absolute;
  right: 0;
  width: 40px;
  height: 40px;
  box-shadow: ${({theme}) => theme.boxShadow};
  padding: 8px;
  margin: 8px;
  border: none;
  background: white;
  background-image: ${({ isCollapsed }) => isCollapsed ? 'url("/assets/images/search.png")' : 'url("/assets/images/close-icon-dark.svg")'};
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60%;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md }) {
    display: none;
  }
`

const MobileOnlyFloorLabel = styled.div`
  box-shadow: ${({theme}) => theme.boxShadow};
  background: white;
  width: auto;
  position: absolute;
  right: 0;
  margin: 8px;
  top: 50px;
  z-index: 9;
  padding: 8px;
`;

const Navigation: FC = () => {
  const {
    searchIsCollapsed,
    toggleSearch
  } = useContext(MobileNavigationContext);

  const {
    selectedFloorName,
  } = useContext(RoomSelectionContext)

  return (
    <>
      <MobileNavigationTrigger
        onClick={() => toggleSearch!()}
        isCollapsed={searchIsCollapsed}
      />
      {selectedFloorName && (
        <MobileOnlyFloorLabel>
          <Trans transKey={selectedFloorName} />
        </MobileOnlyFloorLabel>
      )}
      <Wrapper collapsed={searchIsCollapsed}>
        <RoomInfoWrapper>
          <RoomInfo />
        </RoomInfoWrapper>
        <FloorNavigationWrapper>
          <FloorNavigationButtons />
        </FloorNavigationWrapper>
        <RoomsListWrapper collapsed={searchIsCollapsed}>
          <RoomsList
            // collapsed={searchIsCollapsed}
          />
        </RoomsListWrapper>
      </Wrapper>
    </>
  )
}

export default Navigation;
