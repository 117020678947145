import React, { FC, Suspense, useContext } from "react";
import Floor from "./Floor";
import { ThreeEvent } from "@react-three/fiber";
import { RoomSelectionContext } from "../app/RoomSelectionProvider";
import targetIsRoom from "./targetIsRoom";
import { floors } from "../app/constants";

const MapExplorer: FC = () => {
  const {
    selectRoom,
  } = useContext(RoomSelectionContext);

  const onSelectRoom = (e: ThreeEvent<any>, floorIndex: number) => {
    e.stopPropagation();

    if(targetIsRoom(e.object)) {
      selectRoom!(e.object.name);
      return;
    }

    if(!targetIsRoom(e.object)) {
      selectRoom!(null);
      return;
    }
  }

  return (
    <Suspense fallback={null}>
      {floors.map((floorName, index) => (
        <Floor
          floor={floorName}
          key={floorName}
          floorIndex={index}
          onClick={(e) => onSelectRoom(e, index)}
          totalFloors={floors.length}
        />
      ))}
    </Suspense>
  )
}

export default MapExplorer;
