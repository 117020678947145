import { Floors } from "../navigation/types";

export const floors: Floors[] = [
  Floors.UNDERGROUND,
  Floors.FIRST_FLOOR,
  Floors.SECOND_FLOOR,
  Floors.THIRD_FLOOR,
  Floors.FOURTH_FLOOR,
  Floors.FIFTH_FLOOR,
  Floors.ATTIC,
];
