import styled from "styled-components";

const Pill = styled.div<{ isActive?: boolean}>`
  border-radius: 16px;
  background-color: ${({ theme, isActive = false }) => isActive ? theme.primary : theme.gray};
  transition: background-color 0.1s ease;
  text-transform: uppercase;
  padding: 4px 8px;
  
  display: inline-flex;
  align-items: center;
  width: auto;
  cursor: pointer;
  white-space: nowrap;
`;

export default Pill;
