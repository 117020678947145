import React, { FC } from 'react';
import styled from 'styled-components';
import Pill from '../theme/Pill';
import { FilterType } from "../rooms-list/types";
import Trans from "../i18n";

const Wrapper = styled.div`
  background: white;
  padding: 8px 0 0 0;
`;

const Separator = styled.hr`
  margin: 8px 0;
`;

const ClearFilterButton = styled.button`
  background-color: transparent;
  background-image: url("/assets/images/close-icon.svg");
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  width: 19px;
  height: 19px;
  padding: 0;
  cursor: pointer;
`;

const FiltersWrapper = styled.div`
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
`;

interface Props {
  filters: FilterType[];
  onClearFilter: (filter: FilterType) => void;
}

const ActiveFilters: FC<Props> = ({
  filters,
  onClearFilter,
}) => {
  return (
    <Wrapper>
      <FiltersWrapper>
        {filters.map((filter) => {
          return (
            <Pill
              key={filter}
                isActive
                onClick={() => onClearFilter!(filter)}
            >
              <Trans transKey={filter} />
              <ClearFilterButton />
            </Pill>
          );
        })}
      </FiltersWrapper>
      <Separator />
    </Wrapper>
  )
}

export default ActiveFilters;
