import React, { FC } from "react";
import useScreenSize from "use-screen-size";
import DesktopOrbitControls from "./DesktopOrbitControls";
import MobileOrbitControls from "./MobileOrbitControls";
import { useTheme } from "styled-components";

const CameraControls: FC = () => {
  const { width } = useScreenSize();

  const theme = useTheme();

  if(width <= parseInt(theme.breakpoints.md, 10)) {
    return (
      <MobileOrbitControls />
    )
  }

  return (
      <DesktopOrbitControls />
  )
}

export default CameraControls;
