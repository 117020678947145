import styled from "styled-components";
import { FC, PropsWithChildren, useRef } from "react";

const Wrapper = styled.li<{ isActive: boolean }>`
  padding: 8px;
  cursor: pointer;
  background-color: ${({ theme, isActive }) => isActive ? theme.primary : 'transparent'};
`;

interface Props {
  isActive: boolean;
  onClick: () => void;
}

const RoomName: FC<PropsWithChildren<Props>> = ({
  isActive,
  children,
  onClick,
}) => {
  const ref = useRef<HTMLLIElement>(null);

  return (
    <Wrapper
      ref={ref}
      isActive={isActive}
      onClick={onClick}
    >
      {children}
    </Wrapper>
  )
}

export default RoomName;
