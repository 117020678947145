import english from './english.json';
import romanian from './romanian.json';
import { useContext } from "react";
import { TransContext } from "./TransProvider";

interface LangItem {
  key: string;
  value: string;
}

interface LangFile {
  items: LangItem[];
}

interface LangStrings {
  romanian: LangFile;
  english: LangFile;
}

const langStrings: LangStrings = {
  english,
  romanian
}


const useTranslation = () => {
  const { activeLanguage } = useContext(TransContext);

  return (key: string) => {
    const translation = langStrings[activeLanguage as keyof LangStrings].items.find((item) => item.key === key);

    if(translation) {
      return translation.value;
    }

    return null;
  }
}

export default useTranslation;
