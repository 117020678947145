import { animated, SpringValue, useSpring } from "@react-spring/three";
import { FC, useRef } from "react";
import { ThreeEvent, useFrame, useLoader, useThree } from "@react-three/fiber";
import { Object3D, TextureLoader } from "three";

interface Props {
  x: number;
  y: SpringValue<number> | undefined;
  z: number;
  visible: boolean;
  onClick?: (e: ThreeEvent<any>) => void;
  isSelected: boolean;
  forceFullColor: boolean;
}

const MapPin: FC<Props> = ({
  x,
  y,
  z,
  visible,
  onClick,
  isSelected,
  forceFullColor,
}) => {
  const { camera } = useThree();
  const { scale } = useSpring({
    scale: visible ? (isSelected ? 1.2 : 1) : 0,
  });

  const texture = useLoader(TextureLoader, '/assets/images/map-pin.png');
  const textureFaded = useLoader(TextureLoader, '/assets/images/map-pin-faded.png');

  const ref = useRef<Object3D>(null);

  useFrame(() => {
    ref.current?.lookAt(camera.position);
  });

  return (
    <animated.group
      visible
      position-x={x}
      position-y={y}
      position-z={z}
      scale={scale}
      // @ts-ignore
      ref={ref}
    >
      <mesh
        position-y={1.3}
        onClick={onClick}
      >
        <planeGeometry args={[3.2, 3]} />
        <meshBasicMaterial
          map={(forceFullColor || isSelected) ? texture : textureFaded}
          depthTest={false}
          transparent={true}
        />
      </mesh>
    </animated.group>
  )
}

export default MapPin;
