import { useState } from "react";
import { database, MIN_SEARCH_LENGTH, roomIdsToRooms, tagsDatabase } from "./utils";
import { Result } from "./types";

interface Fields {
  searchActive: boolean;
  onSearchActive: () => void;
  onSearchInactive: () => void;
  searchQuery: string;
  onSearchQueryChange: (query: string) => void;
  searchResults: Result[];
  clearSearch: () => void;
}

type Hook = () => Fields;


const useRoomSearching: Hook = () => {
  const [searchActive, setSearchActive] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const onSearchActive = () => {
    setSearchActive(true);
  }

  const onSearchInactive = () => {
    setSearchActive(false);
  }

  const onSearchQueryChange = (query: string) => {
    const parsedQuery = query.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
    setSearchQuery(parsedQuery);
  }

  const roomSearchResultsIds = database
    .filter(entry => entry.indexOf(searchQuery) !== -1)
    .map(entry => entry.split(':')[0]);

  const tagsSearchResultsIds = tagsDatabase
    .filter(entry => entry.indexOf(searchQuery) !== -1)
    .map(entry => entry.split(':')[0]);

  const searchResults: Result[] = [
    ...(searchQuery.length > MIN_SEARCH_LENGTH ? roomIdsToRooms(roomSearchResultsIds) : []),
    ...(searchQuery.length > MIN_SEARCH_LENGTH ? tagsSearchResultsIds : []),
  ];

  const clearSearch = () => {
    setSearchActive(false);
    setSearchQuery("");
  }

  return {
    searchActive,
    onSearchActive,
    onSearchInactive,
    searchQuery,
    onSearchQueryChange,
    searchResults,
    clearSearch,
  }
}

export default useRoomSearching;
