import { createContext, FC, PropsWithChildren, useEffect, useState } from "react";
import { Object3D } from "three";
import { Floors } from "../navigation/types";
import { floors } from "./constants";
import clamp from "lodash/clamp";
import { FilterType } from "../rooms-list/types";
import rooms from '../navigation/rooms.json';
import uniq from 'lodash/uniq';

interface ContextFields {
  selectRoom?: (roomId: string | null) => void;
  selectFloor?: (floorName: Floors) => void;
  selectedRoom: Object3D | null;
  selectedFloorIndex: number | null;
  selectedFloorName: string | null;
  selectedRoomId: string | null;
  activeFilters: FilterType[];
  goOneFloorUp?: () => void;
  goOneFloorDown?: () => void;
  resetSelection?: () => void;
  selectedRoomMesh: Object3D | null;
  setSelectedRoomMesh?: (mesh: Object3D | null) => void;
  addFilter?: (filter: FilterType) => void;
  removeFilter?: (filter: FilterType) => void;
}

export const RoomSelectionContext = createContext<ContextFields>({
  selectedFloorIndex: null,
  selectedFloorName: null,
  selectedRoomId: null,
  selectedRoom: null,
  activeFilters: [],
  selectRoom: undefined,
  selectFloor: undefined,
  goOneFloorUp: undefined,
  goOneFloorDown: undefined,
  resetSelection: undefined,
  selectedRoomMesh: null,
  setSelectedRoomMesh: undefined,
  addFilter: undefined,
  removeFilter: undefined,
});

type RoomSelectionState = {
  selectedFloorName: string | null;
  selectedFloorIndex: number | null;
  selectedFloor: Floors | null;

  selectedRoomId: string | null;
  selectedRoomMesh: Object3D | null;
}

const getFloorByRoomId = (roomId: string | null) => {
  if(!roomId) {
    return {
      name: null,
      index: null,
    }
  }

  const name = rooms._roomList.find(room => room._id === roomId)?._floor;
  const index = floors.indexOf(name as Floors);

  return {
    name: name || null,
    index: index !== -1 ? index : null,
  }
}

const RoomSelectionProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, setState] = useState<RoomSelectionState>({
    selectedFloorName: null,
    selectedFloorIndex: null,
    selectedFloor: null,

    selectedRoomId: null,
    selectedRoomMesh: null,
  });

  const [
    activeFilters,
    setActiveFilters
  ] = useState<FilterType[]>([]);

  const addFilter = (filter: FilterType) => {
    setActiveFilters(filters => uniq([...filters, filter]));
  }

  useEffect(() => {
    const floorFilter = activeFilters.find(filter => floors.includes(filter as Floors));
    if(floorFilter && !(state.selectedFloorName && state.selectedFloorIndex)) {
      setState((state) => ({
        ...state,
        selectedFloorName: floorFilter,
        selectedFloorIndex: floors.indexOf(floorFilter as Floors),
      }));
    }
    // eslint-disable-next-line
  }, [activeFilters]);

  const removeFilter = (filter: FilterType) => {
    if(activeFilters.length === 1) {
      // Clear selection if the last filter is removed
      resetSelection();
    } else {
      setActiveFilters(filters => filters.filter(f => f !== filter));
    }
  }

  const selectRoom = (roomId: string | null) => {
    if(!roomId) {
      setState({
        ...state,
        selectedRoomId: null,
        selectedFloorName: null,
      });
      return;
    }

    // Always overwrite the active floor state in order to allow jumping between floors
    const floor = getFloorByRoomId(roomId);

    setState((state) => ({
      ...state,
      selectedFloorName: floor.name,
      selectedFloorIndex: floor.index,
      selectedRoomId: roomId
    }));

    if(activeFilters.length === 0) {
      // If no filters are active, add the floor filter type
      setActiveFilters([floor.name as FilterType]);
    } else {
      // If filters are active, make sure the floor is included
      setActiveFilters(filters => uniq([...filters, floor.name as FilterType]));
    }
  }

  const selectFloor = (floorName: Floors) => {
    const floorIndex = floors.indexOf(floorName);

    setState((state) => ({
      ...state,
      selectedRoomId: null,
      selectedFloorName: floorName,
      selectedFloorIndex: floorIndex,
    }));
    setActiveFilters([floorName as FilterType]);
  }
  const goOneFloorUp = () => {
    const floorIndex = state.selectedFloorIndex === null ? 0 : state.selectedFloorIndex + 1;
    const floorName = floors[clamp(floorIndex, 0, floors.length - 1)];

    selectFloor(floorName);
  }

  const goOneFloorDown = () => {
    const floorIndex = state.selectedFloorIndex === null ? 0 : state.selectedFloorIndex - 1;
    const floorName = floors[clamp(floorIndex, 0, floors.length - 1)];

    selectFloor(floorName);
  }

  const resetSelection = () => {
    setState({
      selectedFloorName: null,
      selectedFloorIndex: null,
      selectedFloor: null,
      selectedRoomId: null,
      selectedRoomMesh: null,
    });
    setActiveFilters([]);
  }

  // Only used internally by the Floor component
  const setSelectedRoomMesh = (roomMesh: Object3D | null) => {
    setState((state) => ({
      ...state,
      selectedRoomMesh: roomMesh,
    }));
  }

  return (
    <RoomSelectionContext.Provider value={{
      selectRoom,
      selectFloor,

      selectedRoom: null,

      selectedFloorIndex: state.selectedFloorIndex,
      selectedFloorName: state.selectedFloorName,
      selectedRoomId: state.selectedRoomId,

      selectedRoomMesh: state.selectedRoomMesh,
      setSelectedRoomMesh,

      goOneFloorUp,
      goOneFloorDown,
      resetSelection,

      activeFilters,
      addFilter,
      removeFilter,
    }}>
      {children}
    </RoomSelectionContext.Provider>
  )
}

export default RoomSelectionProvider;
