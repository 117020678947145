import { Floors, Room, RoomsByFloor } from "../navigation/types";
import { floors } from "../app/constants";
import roomsList from "../navigation/rooms.json";
import romanianTrans from "../i18n/romanian.json";
import englishTrans from "../i18n/english.json";
import rooms from "../navigation/rooms.json";

export const MIN_SEARCH_LENGTH = 2;

type GroupRoomsByFloor = (rooms: Room[]) => RoomsByFloor;

export const groupRoomsByFloor: GroupRoomsByFloor = (rooms: Room[]) => {
  const roomsByFloor: RoomsByFloor = {} as Record<Floors, Room[]>;

  floors.forEach(floorName => {
    roomsByFloor[floorName] = [];
  });

  rooms.forEach((room) => {
    const floor = room._floor as Floors;
    if(!roomsByFloor[floor]) {
      roomsByFloor[floor] = [];
    }

    roomsByFloor[floor].push(room);
  });

  return roomsByFloor;
}


export const flattenDB = () => {
  const items = [];

  for(let i = 0; i < roomsList._roomList.length; i++) {
    const roomId = roomsList._roomList[i]._id;
    // Serialized format is `id:roTranslation:enTranslation:floor:roTags:enTags`
    const item = [
      roomId,
      romanianTrans.items.find(transItem => transItem.key === `${roomId}_name`)?.value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
      englishTrans.items.find(transItem => transItem.key === `${roomId}_name`)?.value.toLowerCase(),
      roomsList._roomList[i]._floor.toLowerCase(),
      roomsList._roomList[i]._tags.map(tag => romanianTrans.items.find(transItem => transItem.key === tag)?.value.toLowerCase()).join(',').normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
      roomsList._roomList[i]._tags.map(tag => englishTrans.items.find(transItem => transItem.key === tag)?.value.toLowerCase()).join(','),
    ];

    items.push(item.join(':'));
  }

  return items;
}

export const roomIdsToRooms = (roomIds: string[]) => {
  return rooms._roomList.filter(room => roomIds.includes(room._id))
}

export const database = flattenDB();

const flattenTagsDb = () => {
  const items = [];

  const romanianTags = romanianTrans.items.filter(item => item.key.includes('Tag-'));
  const englishTags = englishTrans.items.filter(item => item.key.includes('Tag-'));

  for(let i = 0; i < romanianTags.length; i++) {
    const tagId = romanianTags[i].key;
    // Serialized format is `id:roTranslation:enTranslation`
    const item = [
      tagId,
      romanianTags[i].value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
      englishTags[i].value.toLowerCase(),
    ];

    items.push(item.join(':'));
  }

  return items;
}

export const tagsDatabase = flattenTagsDb();
