import React, { FC, useContext } from "react";
import styled from "styled-components";
import useTranslation from "../i18n/useTranslation";
import { RoomSelectionContext } from '../app/RoomSelectionProvider';
import ActiveFilters from "./ActiveFilters";
import useRoomListing from "./useRoomListing";
import RoomsByFloorListing from "./RoomsByFloorListing";
import { Room, RoomsByFloor } from "../navigation/types";
import Results from "./Results";
import useRoomSearching from "./useRoomSearching";
import PendingSearch from "./PendingSearch";
import { MIN_SEARCH_LENGTH } from "./utils";
import { MobileNavigationContext } from "../app/MobileNavigationProvider";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  
  transition: transform 0.1s ease;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md }) {
    transition: unset;
    transform: unset;
  }
`;

const SearchBarWrapper = styled.div`
  background: white;
  box-shadow: ${({ theme }) => theme.boxShadow};
  padding: 8px;
  margin: 8px;
  width: calc(100% - 40px - 16px - 8px);
  height: 40px;
  
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md }) {
    width: auto;
  }
`;

const SearchInput = styled.input`
  width: 100%;
  border: none;
  height: 100%;
`;


const ListWrapper = styled.div`
  background-color: white;
  box-shadow: ${({theme}) => theme.boxShadow};

  margin: 0 8px 8px;
  padding: 0 16px 16px;

  //min-height: 90%;
  min-height: calc(100% - 40px - 24px);
  max-height: calc(100vh - 64px);
  overflow-y: auto;
  position: relative;
`;

const ActiveFiltersWrapper = styled.div`
  position: sticky;
  top: 0;
`;

const RoomsList: FC = () => {
  const t = useTranslation();

  const {
    activeFilters,
    removeFilter,
    selectRoom,
    addFilter,
  } = useContext(RoomSelectionContext);

  const {
    searchActive,
    onSearchActive,
    onSearchInactive,
    searchQuery,
    onSearchQueryChange,
    searchResults,
    clearSearch,
  } = useRoomSearching();

  const {
    toggleSearch
  } = useContext(MobileNavigationContext);

  const onSearchInputFocus = () => {
    onSearchActive();
  }

  const onSearchInputBlur = () => {
    if(searchQuery.length === 0) {
      onSearchInactive();
    }
  }

  const {
    items
  } = useRoomListing({
    activeFilters,
  });

  const onRoomSelected = (roomId: string) => {
    toggleSearch!();
    selectRoom!(roomId);
    if(searchQuery.length > 0) {
      clearSearch();
    }
  }

  const onTagSelected = (tag: string) => {
    addFilter!(tag);
    if(searchQuery.length > 0) {
      clearSearch();
    }
  }

  return (
    <Wrapper>
      <SearchBarWrapper>
        <SearchInput
          value={searchQuery}
          onChange={(e) => onSearchQueryChange(e.target.value)}
          onFocus={onSearchInputFocus}
          onBlur={onSearchInputBlur}
          placeholder={t('search_placeholder')!}
        />
      </SearchBarWrapper>

      <ListWrapper>
        {activeFilters.length > 0 && !searchActive && (
          <ActiveFiltersWrapper>
            <ActiveFilters filters={activeFilters} onClearFilter={removeFilter!}/>
          </ActiveFiltersWrapper>
        )}

        {searchActive && searchQuery.length <= MIN_SEARCH_LENGTH && <PendingSearch />}

        {!searchActive && activeFilters.length === 0 && (
          <RoomsByFloorListing
            items={items as RoomsByFloor}
            onRoomSelected={onRoomSelected}
          />
        )}

        {searchActive && searchResults.length > 0 && (
          <Results
            items={searchResults as Room[]}
            onRoomSelected={onRoomSelected}
            onTagSelected={onTagSelected}
          />
        )}

        {!searchActive && activeFilters.length > 0 && (
          <Results
            items={items as Room[]}
            onRoomSelected={onRoomSelected}
            onTagSelected={onTagSelected}
          />
        )}
      </ListWrapper>

    </Wrapper>
  )
}

export default RoomsList;
