import { ThreeEvent, useLoader } from "@react-three/fiber";
import { FC, useContext, useMemo } from "react";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { useSpring, animated } from "@react-spring/three";
import { Object3D, Vector3 } from "three";
import targetIsRoom from "./targetIsRoom";
import MapPin from "./MapPin";
import { Floors } from "../navigation/types";
import { RoomSelectionContext } from "../app/RoomSelectionProvider";

const FLOOR_SPACING = 15;
const ALIGNMENT_OFFSET_Y = -40;
const OUT_OF_VIEW_OFFSET = 10;

const getOffset = (index: number, selectedFloorIndex: number | null) => {
  if (selectedFloorIndex === null) {
    return index * FLOOR_SPACING + ALIGNMENT_OFFSET_Y;
  }

  if (index < selectedFloorIndex) {
    return (index - OUT_OF_VIEW_OFFSET) * FLOOR_SPACING + ALIGNMENT_OFFSET_Y;
  }

  if (index > selectedFloorIndex) {
    return (index + OUT_OF_VIEW_OFFSET) * FLOOR_SPACING + ALIGNMENT_OFFSET_Y;
  }
}

const getSelectedOffset = (totalFloors: number) => {
  return totalFloors / 2 * FLOOR_SPACING + ALIGNMENT_OFFSET_Y;
}

const MESH_FLOOR_NAMES = ['SUBSOL', 'PARTER', 'ETAJ_1', 'ETAJ_2', 'ETAJ_3', 'ETAJ_4', 'POD'];

const floorMeshesOnly = (meshChildren: Object3D) => {
  return MESH_FLOOR_NAMES.indexOf(meshChildren.name) > -1;
}

const FloorToModelFilename: Record<Floors, string> = {
  [Floors.UNDERGROUND]: 'subsol.glb',
  [Floors.FIRST_FLOOR]: 'parter.glb',
  [Floors.SECOND_FLOOR]: 'etaj1.glb',
  [Floors.THIRD_FLOOR]: 'etaj2.glb',
  [Floors.FOURTH_FLOOR]: 'etaj3.glb',
  [Floors.FIFTH_FLOOR]: 'etaj4.glb',
  [Floors.ATTIC]: 'etaj5.glb',
}

interface Props {
  floor: Floors;
  floorIndex: number;
  onClick: (e: ThreeEvent<any>) => void;
  totalFloors: number;
}

interface IPin {
  uuid: string;
  position: Vector3;
  roomName: string;
}

const Floor: FC<Props> = ({
  floor,
  floorIndex,
  onClick,
  totalFloors,
}) => {
  const {
    selectedRoomId,
    setSelectedRoomMesh,
    selectedFloorIndex,
  } = useContext(RoomSelectionContext);

  const { scene } = useLoader(GLTFLoader, `/assets/models/${FloorToModelFilename[floor]}`);

  const isSelected = floorIndex === selectedFloorIndex;

  const { y } = useSpring({
    config: {
      damping: floorIndex * 10 + 1,
    },
    y: isSelected ? getSelectedOffset(totalFloors) : getOffset(floorIndex, selectedFloorIndex),
  });

  // Each floor will decide whether a room should be sent to the context in order to be highlighted
  useMemo(() => {
    if(!selectedRoomId) {
      setSelectedRoomMesh!(null);
    }

    if(selectedFloorIndex !== floorIndex) {
      return;
    }

    scene.traverse((object) => {
      if(object.name === selectedRoomId) {
        setSelectedRoomMesh!(object);
      }
    });
    // eslint-disable-next-line
  }, [selectedRoomId]);

  const pins = useMemo(() => {
    const floorChildMeshes = scene.children[0].children.find(floorMeshesOnly)?.children;

    if(!floorChildMeshes) {
      return [];
    }

    return floorChildMeshes.map((child: Object3D) => {
      if (targetIsRoom(child)) {
        const roomPosition = new Vector3();
        child.getWorldPosition(roomPosition);
        return {
          uuid: child.uuid,
          roomName: child.name,
          position: roomPosition,
        };
      }

      return null;
    });
  }, [scene]);

  const handleClick = (e: ThreeEvent<any>) => {
    onClick(e);
  }

  return (
    <group
      position-x={0}
      position-y={0}
      position-z={0}
    >
      {pins.map((pin: IPin | null) => {
        if(!pin) {
          return null;
        }

        return (
          <MapPin
            x={pin.position.x}
            y={y}
            z={pin.position.z}
            key={`pin-${pin.uuid}`}
            visible={isSelected}
            isSelected={selectedRoomId === pin.roomName}
            forceFullColor={!selectedRoomId}
          />
        );
      })}

      <animated.mesh
        position-x={0}
        position-y={y}
        position-z={0}
        rotation-y={-2.5}
      >
        <primitive
          object={scene}
          scale={[1, 1, 1]}
          onClick={handleClick}
        />
      </animated.mesh>
    </group>
  );
}

export default Floor;
