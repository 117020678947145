import { EffectComposer, Outline } from "@react-three/postprocessing";
import { BlendFunction, KernelSize } from "postprocessing";
import React, { FC, useContext } from "react";
import { RoomSelectionContext } from "./RoomSelectionProvider";

const Effects: FC = () => {
  const { selectedRoomMesh } = useContext(RoomSelectionContext);

  return (
    <EffectComposer autoClear={false}>
      <Outline
        selection={selectedRoomMesh ? [selectedRoomMesh] : []}
        selectionLayer={10}
        blendFunction={BlendFunction.SCREEN}
        edgeStrength={5}
        visibleEdgeColor={0xF89C20}
        hiddenEdgeColor={0xB76F06}
        kernelSize={KernelSize.MEDIUM}
        width={1024}
        height={1024}
        blur={true}
        xRay={false}
      />
    </EffectComposer>
  );
}

export default Effects;
